<template>
    <div class="columnist-wall row">
        <div v-for="post in postsFull" class="columnist-column col-12 col-sm-6 col-md-4 mb-3">
            <div class="card shadow">
                <nuxt-link v-if="post.hero" :to="generateLink(post.slug)">
                    <img :src="post.hero.src" :class="['w-100', post.hero.class]" :alt="post.hero.alt" loading="lazy">
                </nuxt-link>
                <nuxt-link :to="generateLink(post.slug)" class="card-body text-center h4 p-3 mb-0 post-title">
                    <Spelling :text="post.title" />
                </nuxt-link>
                <div v-if="details" class="card-footer small">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item small">
                            <Icon v="calendar" />
                            {{ post.date }}
                        </li>
                        <li v-for="author in post.authors" class="list-inline-item">
                            <nuxt-link v-if="author.startsWith('@')" :to="`/${author}`" class="badge bg-light text-dark border">
                                <Icon v="collective-logo.svg" class="invertible" />
                                {{ author }}
                            </nuxt-link>
                            <span v-else class="badge bg-light text-dark border">
                                {{ author }}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Columnist from 'avris-columnist';
import useConfig from '../composables/useConfig.ts';

export default {
    props: {
        posts: { required: true },
        details: { type: Boolean },
    },
    setup(props) {
        const { data: postsFull } = useAsyncData(`posts-${JSON.stringify(props.posts)}`, async () => {
            if (!props.posts.length) {
                return [];
            }
            if (typeof props.posts[0] === 'object') {
                return props.posts;
            }
            return await $fetch('/api/blog', {
                params: {
                    slugs: props.posts,
                },
            });
        });

        return {
            config: useConfig(),
            postsFull,
        };
    },
    data() {
        const shortcuts = {};
        if (this.config.blog && this.config.blog.shortcuts) {
            for (const shortcut in this.config.blog.shortcuts) {
                if (!this.config.blog.shortcuts.hasOwnProperty(shortcut)) {
                    continue;
                }
                shortcuts[this.config.blog.shortcuts[shortcut]] = shortcut;
            }
        }

        return {
            shortcuts,
        };
    },
    async mounted() {
        const columnist = new Columnist(this.$el);
        columnist.start();
    },
    methods: {
        generateLink(slug) {
            return this.shortcuts[slug] !== undefined && !(this.config.blog.keepFullPath || []).includes(slug)
                ? `/${this.shortcuts[slug]}`
                : `/${this.config.links.blogRoute}/${slug}`;
        },
    },
};
</script>

<style lang="scss" scoped>
    .columnist-wall > .columnist-column {
        transition: margin-top .2s ease-in-out;
    }
    .post-title {
        text-wrap: balance;
    }
</style>
